// 지도 및 네비게이션 설정 함수 모듈
import { initializeMap } from '../../utils/map/mapControl.js'; // 카카오맵 검색 모듈 초기화
import { setupMapsAndNavigation } from '../template_modules/template_marriageInfo_modules/setupMapsAndNavigation.js';

// 캘린더 관리 함수 모듈
import { dateChange } from '../../utils/calendar/dateChange.js'; // 셀렉트박스에 날짜 입력시, 캘린더 업데이트처리
import { dateChangeInvitation } from '../../utils/calendar/dateChangeInvitation.js'; // DB에서 선택된 날짜 조회후, 캘린더 업데이트처리
import { handleDateInputChange } from '../../utils/calendar/countdown.js'; // 캘린더 초기화 함수 모듈
import { updateDateFormat_template } from './template_marriageInfo_modules/updateDateFormat_template.js';
import { locationInfo_map_or_image } from './template_marriageInfo_modules/locationInfo_map_or_image.js';


function template_marriageInfo(dbInfo, currentUrl, dynamicValue) {

    // 카카오맵 위치정보 검색 / 마커위치정보 초기화 관련 함수
    initializeMap(currentUrl);

    // // DB에서 조회해온 데이터를 통해 카카오 지도에 표시 및, 네이버지도, 카카오맵 길찾기에 위치데이터를 연동하는 함수
    setupMapsAndNavigation(currentUrl);

    // 예식정보 탭 클릭시, 브라우저 이벤트를 걸어서 예식장 지도 재렌더링
    const weddingInfoItem = document.getElementById('categoryWrap_item_weddingInfo');
    if (weddingInfoItem) {
        weddingInfoItem.addEventListener('click', () => {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize')); // 브라우저의 resize 이벤트 트리거

                // highlight 위치 업데이트
                const activeElement = document.querySelector('.titleImg_item_contents_item_button_weddingNotationMethod_wrap.active');
                if (activeElement) {
                    updateHighlightPosition(activeElement);
                }
            }, 200); // 0.2초 후 실행하여 레이아웃이 완전히 반영되도록 함
        });
    }


    // 디데이 토글 모듈
    let toggleDDay = document.getElementById('DDay');
    if (toggleDDay) {
        toggleDDay.onclick = () => {
            toggleDDay.classList.toggle('active');
            let DDayClass = document.querySelectorAll(".ddaysection");
            let WeddingCounterWrap = document.querySelector('.WeddingCounterWrap');

            DDayClass.forEach(DDayClass => {
                if (DDayClass.style.display === "none") {
                    DDayClass.style.display = "flex";
                    WeddingCounterWrap.style.display = "flex";
                } else {
                    DDayClass.style.display = "none";
                    WeddingCounterWrap.style.display = "none";
                }
            });
        }
    }

    // 계좌번호 정보 입력 모듈
    // 공통 로직을 처리하는 함수
    function handleAccountInfoUpdate(section, inputSelector, contentSelector, event) {
        const target = event.target;
        const parentElement = target.closest('.AccountInputs');

        if (!parentElement) {
            console.error('Parent element not found for selector:', '.AccountInputs');
            return;
        }

        const index = Array.from(parentElement.parentNode.children).indexOf(parentElement);
        const inputElement = parentElement.querySelector(inputSelector);

        if (!inputElement) {
            console.error('Input element not found for selector:', inputSelector);
            return;
        }

        updateAccordionContentValue(section, index, inputElement, contentSelector);
    }

    // 계좌정보 입력 관련 함수들
    function printBankInfo(section, inputSelector, contentSelector, event) {
        handleAccountInfoUpdate(section, inputSelector, contentSelector, event);
    }

    // 인풋 값 변경 시 처리
    document.body.addEventListener('keyup', function (event) {
        const target = event.target;

        // 계좌 정보 입력 필드의 ID와 관련 함수들을 매핑
        const bankFieldFunctions = {
            'groom_bank_nameTerms_Input': 'groom_bank_nameTerms',
            'groom_bank_holder_Input': 'groom_holderinfo',
            'groom_bank_bankName_Input': 'groom_bank_bankName',
            'groom_bank_accountInfo_Input': 'groom_bank_accountInfo',
            'bride_bank_nameTerms_Input': 'bride_bank_nameTerms',
            'bride_bank_holder_Input': 'bride_holderinfo',
            'bride_bank_bankName_Input': 'bride_bank_bankName',
            'bride_bank_accountInfo_Input': 'bride_bank_accountInfo'
        };

        // 타겟 엘리먼트의 ID가 위 매핑에 포함되어 있는 경우에만 처리
        const functionName = bankFieldFunctions[target.id];
        if (functionName) {
            printBankInfo(target.id.split('_')[0], `#${target.id}`, `#${functionName}`, event);
        }
    });

    // 카카오페이 입력 필드의 값이 변경될 때 처리하는 함수
    function updateKakaoPayDataValue(event) {
        const target = event.target;
        const parentElement = target.closest('.AccountInputs');
        const section = parentElement.closest('.GroomAccountInputGroups') ? 'groom' : 'bride';

        // 버튼 선택자를 생성합니다.
        const buttonSelector = `.Btn_KaKaoPay_${section}`;

        // 섹션에 맞는  버튼을 ��습니다.
        const buttons = document.querySelectorAll(buttonSelector);
        const index = Array.from(parentElement.parentNode.children).indexOf(parentElement);

        if (buttons.length > index) {
            buttons[index].setAttribute('data-value', target.value);
            console.log(`Updated ${section} KakaoPay button at index ${index} with value: ${target.value}`);
        } else {
            console.error(`Button not found for ${section} at index ${index}`);
        }
    }

    // 카카오페이 입력 필드에 이벤트 리스너 추가
    document.body.addEventListener('input', function (event) {
        if (event.target.classList.contains('groom_input_kakaoPay') || event.target.classList.contains('bride_input_kakaoPay')) {
            updateKakaoPayDataValue(event);
            console.log(`Input event detected on ${event.target.className} with value: ${event.target.value}`);
        }
    });


    // 신랑 신부 계좌정보 작성
    function updateAccordionContentValue(section, index, inputElement, contentSelector) {
        let inputValue = inputElement.value;

        let selectedAccordion = document.getElementById(`accordion-title-${section}`);
        if (!selectedAccordion) return;  // Check if selectedAccordion exists

        let accodianAccountsGroup = selectedAccordion.nextElementSibling;
        let accodianAccountsContents = accodianAccountsGroup.querySelectorAll('.accodian-inner');

        if (accodianAccountsContents.length <= index) return;  // Check if the index is within range

        let contentIndex = accodianAccountsContents[index].querySelector(contentSelector);

        if (contentIndex) { // Null check for contentIndex
            contentIndex.innerText = inputValue;
        } else {
            console.error('contentIndex not found for', contentSelector);
        }
    }

    dateChangeInvitation(dynamicValue);


    // 셀렉트 박스에서 선택한 오후/오전, 시간, 분 가져오기
    let ampmSelect = document.getElementById("SelectAMPM"); // 오후/오전 선택하는 셀렉트 박스의 ID
    let hourSelect = document.getElementById("SelectTime"); // 시간 선택하는 셀렉트 박스의 ID
    console.log(hourSelect);
    let minuteSelect = document.getElementById("SelectMinute"); // 분 선택하는 셀렉트 박스의 ID


    // 사용자 입력에 대해서는 handleDateInputChange 함수 사용
    const dateInput = document.getElementById("date");
    dateInput.addEventListener("change", handleDateInputChange);
    // 셀렉트 박스 변경시 함수를 호출하기 위한 이벤트 리스너 추가
    ampmSelect.addEventListener("change", handleDateInputChange);
    hourSelect.addEventListener("change", handleDateInputChange);
    minuteSelect.addEventListener("change", handleDateInputChange);
    dateInput.addEventListener("change", dateChange);







    // 모든 textarea에 이벤트 리스너 추가
    var transportationInputs = document.querySelectorAll('.TextBox');
    transportationInputs.forEach(function (input) {
        input.addEventListener('input', function () {
            // 이 textarea의 data-index를 가져옴
            var index = this.closest('.checkselection_address_item').getAttribute('data-index');
            // 해당하는 transportation_method_item을 찾음
            var bodyText = document.querySelector(`.transportation_method_item[data-index="${index}"] .WeddingBodyText`);
            // 값을 업데이트
            if (bodyText) {
                bodyText.innerHTML = this.value.replace(/\n/g, '<br>');
            }
        });
    });


    document.addEventListener('change', function (event) {
        if (event.target.classList.contains('selectbox_transportation')) {
            var selectBox = event.target;
            var correspondingItem = selectBox.closest('.checkselection_address_item');
            var dataIndex = correspondingItem.getAttribute('data-index');
            var transportationMethodContainer = document.querySelector('.transportation_method');
            var correspondingMethod = transportationMethodContainer.querySelector(`.transportation_method_item[data-index="${dataIndex}"]`);

            if (correspondingMethod) {
                updateTransportationSelection(selectBox, correspondingMethod);
            }
        }
    });


    document.addEventListener('click', function (event) {
        if (event.target.classList.contains('delete_transportation_img')) {
            var deleteButton = event.target;
            var indexToDelete = deleteButton.closest('.checkselection_address_item').getAttribute('data-index');
            var transportationMethodContainer = document.querySelector('.transportation_method');
            var methodToDelete = transportationMethodContainer.querySelector(`.transportation_method_item[data-index="${indexToDelete}"]`);

            if (methodToDelete) {
                methodToDelete.remove();
            }
            deleteButton.closest('.checkselection_address_item').remove();
            updateIndexes();
        }
    });





    // 교통수단 모듈 정리
    function addTransportation() {
        var checkSelectionWrap = document.querySelector('.checkselection_wrap');
        var transportationMethodContainer = document.querySelector('.transportation_method');

        var existingItems = document.querySelectorAll('.checkselection_address_item');
        var newIndex = existingItems.length + 1;

        var newCheckSelection = document.createElement('div');
        newCheckSelection.className = 'checkselection_address_item';
        newCheckSelection.id = 'checkselection_address_item_address_item';
        newCheckSelection.setAttribute('data-index', newIndex);
        newCheckSelection.setAttribute('data-id', 'parking');

        newCheckSelection.addEventListener('click', function () {
            const targetElement = document.querySelector('.transportation_method');
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        });

        newCheckSelection.innerHTML = `
            <div class="checkselection_inner">
                <select class="selectbox_transportation" id="selectbox_transportation">
                    <option value="주차안내" data-value="parking">주차안내</option>
                    <option value="자가용" data-value="car">자가용</option>
                    <option value="지하철" data-value="subway">지하철</option>
                    <option value="버스" data-value="bus">버스</option>
                    <option value="기차" data-value="train">기차</option>
                    <option value="도보" data-value="walking">도보</option>
                    <option value="셔틀버스" data-value="shuttlebus">셔틀버스</option>
                    <option value="버스대절" data-value="weddingbus">버스대절</option>
                    <option value="택시" data-value="taxi">택시</option>
                </select>
                <div class="delete_transportation">
                    <img class="delete_transportation_img" src="../Resource/templates/utility/delete.svg" alt="삭제">
                </div>
            </div>
            <textarea class="TextBox" name="" id="input_transportation" placeholder="이용방법을 입력해 주세요"></textarea>
        `;

        checkSelectionWrap.appendChild(newCheckSelection);

        var deleteButton = newCheckSelection.querySelector('.delete_transportation');
        deleteButton.addEventListener('click', deleteTransportation);

        var newTransportationMethod = createTransportationMethod(newIndex, 'parking');
        transportationMethodContainer.appendChild(newTransportationMethod);

        var newSelectBox = newCheckSelection.querySelector('.selectbox_transportation');
        newSelectBox.addEventListener('change', function () {
            updateTransportationSelection(this, newTransportationMethod);
        });

        var newTextBox = newCheckSelection.querySelector('.TextBox');
        newTextBox.addEventListener('input', function () {
            updateTransportationBody(this, newTransportationMethod);
        });
    }

    function deleteTransportation() {
        var indexToDelete = this.closest('.checkselection_address_item').getAttribute('data-index');
        var transportationMethodContainer = document.querySelector('.transportation_method');
        var methodToDelete = transportationMethodContainer.querySelector(`.transportation_method_item[data-index="${indexToDelete}"]`);
        if (methodToDelete) {
            methodToDelete.remove();
        }
        this.closest('.checkselection_address_item').remove();
        updateIndexes();
    }

    function createTransportationMethod(index, id) {
        var newTransportationMethod = document.createElement('div');
        newTransportationMethod.className = 'transportation_method_item';
        newTransportationMethod.setAttribute('data-id', id);
        newTransportationMethod.setAttribute('data-index', index);
        newTransportationMethod.innerHTML = `
            <div class="transportation_method_item_inner">
                <div class="WeddingSubtitleText" id="transportation_method_item_title">
                    주차안내
                </div>
                <img class="transportation_method_item_img" src="../../../Resource/templates/utility/parking.svg" alt="">
            </div>
            <div class="WeddingBodyText" id="transportation_method_item_inner_body">
                이용방법을 입력해 주세요
            </div>
        `;
        return newTransportationMethod;
    }

    function updateTransportationSelection(selectBox, transportationMethod) {
        var selectedOption = selectBox.options[selectBox.selectedIndex];
        var selectedValue = selectedOption.getAttribute('data-value');

        var correspondingItem = selectBox.closest('.checkselection_address_item');
        correspondingItem.setAttribute('data-id', selectedValue);

        transportationMethod.setAttribute('data-id', selectedValue);
        transportationMethod.querySelector('.WeddingSubtitleText').textContent = selectedOption.text;
        transportationMethod.querySelector('.transportation_method_item_img').src = `../../../Resource/templates/utility/${selectedValue}.svg`;
    }

    function updateTransportationBody(textBox, transportationMethod) {
        var bodyText = transportationMethod.querySelector('.WeddingBodyText');
        bodyText.innerHTML = textBox.value.replace(/\n/g, '<br>');
    }

    function updateIndexes() {
        const allItems = document.querySelectorAll('.checkselection_address_item');
        allItems.forEach((item, index) => {
            item.setAttribute('data-index', index + 1);
            const transportationMethodContainer = document.querySelector('.transportation_method');
            const correspondingMethod = transportationMethodContainer.querySelector(`.transportation_method_item[data-index="${index + 2}"]`);
            if (correspondingMethod) {
                correspondingMethod.setAttribute('data-index', index + 1);
            }
        });

        // transportation_method_item의 인덱도 갱신
        const allMethods = document.querySelectorAll('.transportation_method_item');
        allMethods.forEach((method, index) => {
            method.setAttribute('data-index', index + 1);
        });
    }


    document.getElementById('add_transportation').addEventListener('click', addTransportation);


    const addActiveClass = (elements, callback) => {
        elements.forEach(element => {
            element.addEventListener('click', function () {
                elements.forEach(el => el.classList.remove('active'));
                this.classList.add('active');
                if (callback) callback(this);
            });
        });
    };

    const updateHighlightPosition = (element) => {
        const highlight = document.querySelector('.titleImg_item_contents_item_buttons_weddingNotationMethod_wrap_highlight');
        if (!highlight) {
            console.error('Highlight element not found');
            return;
        }

        const elementRect = element.getBoundingClientRect();
        const parentRect = element.parentElement.getBoundingClientRect();

        const leftPosition = elementRect.left - parentRect.left + element.parentElement.scrollLeft;
        const topPosition = elementRect.top - parentRect.top + element.parentElement.scrollTop;

        console.log('Element Rect:', elementRect);
        console.log('Parent Rect:', parentRect);
        console.log('Calculated Left:', leftPosition);
        console.log('Calculated Top:', topPosition);

        highlight.style.width = `${elementRect.width}px`;
        highlight.style.height = `${elementRect.height}px`;
        highlight.style.left = `${leftPosition}px`;
        highlight.style.top = `${topPosition}px`;
    };

    const addDotActiveClass = (elements) => {
        elements.forEach(element => {
            const dot = element.querySelector('.titleImg_item_contents_item_button_weddingNotationMethod_dot');
            element.addEventListener('click', function () {
                elements.forEach(el => {
                    const elDot = el.querySelector('.titleImg_item_contents_item_button_weddingNotationMethod_dot');
                    if (elDot) elDot.classList.remove('active');
                });
                if (dot) dot.classList.add('active');
            });
        });
    };

    // 예식일자 표기방식 초기화
    const weddingDateFormat = dbInfo.weddingDate_format[0]['data-value'];
    const initialDecorationSelection = document.querySelectorAll('.titleImg_item_contents_item_button_weddingNotationMethod_wrap');

    setTimeout(() => {
        initialDecorationSelection.forEach(element => {
            const dataValue = element.getAttribute('data-value');
            element.classList.remove('active');
            const dot = element.querySelector('.titleImg_item_contents_item_button_weddingNotationMethod_dot');
            if (dot) dot.classList.remove('active');

            if (dataValue === weddingDateFormat) {
                element.classList.add('active');
                if (dot) dot.classList.add('active');
                updateHighlightPosition(element);
            }
        });
    }, 100);

    // 기존 코드
    const decorationSelection = document.querySelectorAll('.titleImg_item_contents_item_button_weddingNotationMethod_wrap');

    addActiveClass(decorationSelection, (element) => {
        updateHighlightPosition(element);

        const activeElement = document.querySelector('.titleImg_item_contents_item_button_weddingNotationMethod_wrap.active');
        if (activeElement) {
            const dataValue = activeElement.getAttribute('data-value');
            updateDateFormat_template(dataValue);
        }
    });

    addDotActiveClass(decorationSelection);

    // 지도에 지도로 표기할지 이미지로 표기할지 선택하는 모듈
    locationInfo_map_or_image(dbInfo, dynamicValue);

    
    



}
export default template_marriageInfo;